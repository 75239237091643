<template>
  <div v-if="data.possibleValues !== null">
    <bc-select
      v-model="selectedParameter.value"
      :items="parameters"
      item-text="text"
      item-value="value"
      :label="getGroupLabel()"
      :menu-props="{ auto: true }"
      :returnObject="false"
      :multiple="multiselect"
    ></bc-select>
  </div>
</template>

<script>
import { BcSelect } from '@brain/ui'

export default {
  name: 'ScenarioParameterSelect',
  components: { BcSelect },
  data() {
    return {
      value: null,
      selectedParameter: {
        value: []
      }
    }
  },
  computed: {
    parameters() {
      return this.getObject(this.data.possibleValues)
    }
  },
  props: {
    parameterData: {
      type: Object,
      require: true
    },
    multiselect: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.selectedParameter.value = this.multiselect
      ? this.parameterData.value
          .split(',')
          .map((item) => item.trim())
          .sort()
      : this.parameterData.value
    this.data = this.parameterData
  },
  methods: {
    getGroupLabel() {
      return this.$t(`application.parameters.items.${this.parameterData.code}`)
    },
    getObject(possibleValue) {
      return possibleValue.map((value) => ({
        text: isNaN(Number(value))
          ? this.$t(`application.parameters.options.${value}`)
          : value,
        value: value
      }))
    },
    getData() {
      return this.multiselect
        ? this.selectedParameter.value.sort().join(', ')
        : this.selectedParameter.value
    }
  }
}
</script>

<style lang="scss" scoped>
.group-text-color {
  color: var(--bc-toast-color);
}
</style>
