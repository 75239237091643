export default {
  crud: {
    filter: {
      selectAllSearchResults: 'Select All Search Results',
      addCurrentSelectionToFilter: 'Add current selection to filter',
      maxFilterQuery:
        'Error! Cannot select more than {maxFilterQuery} items per filter'
    }
  },
  core: {
    misc: {
      deleteFiltered: 'Delete filtered'
    },
    crud: {
      alertRemoveFilteredMessage:
        'Are you sure you want to remove the filtered itens?',
      alertRemoveFilteredWithoutFilterMessage:
        'There is no filter applied! Are you sure you want to remove all items?'
    },
    language: 'en',
    dateFormat: 'MM/DD/YYYY',
    monthFormat: 'MM/YYYY',
    timeFormat: 'hh:mm A',
    datetimeFormat: 'MM/DD/YYYY hh:mm A',
    clockTimeFormat: 'ampm'
  },
  application: {
    name: 'Supply Brain - en',
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'MM/DD/YYYY HH:mm',
    dateTimeFullFormat: 'MM/DD/YYYY HH:mm:ss',
    monthYearChart: 'MMM/YY',
    fullMonthYearChart: 'YYYY[,] MMMM',
    thousandsSeparator: ',',
    decimalSeparator: '.',
    crud: {
      uniqueKeyError: 'Error! Unique key duplication!',
      foreignKeyViolationError: 'Foreign Key Violation!'
    },
    terms: {
      restrictions:
        "Restrictions: The Customer must use the Software under the terms and for the purposes expressly provided for in the current agreement. Customer may not use, copy, modify or distribute the Licensed Materials (electronically or by any other means), nor any copy, adaptation, transcript or merged portion thereof, except as expressly authorized by this Agreement. Customer will not (and will not permit others to) reverse-assemble, reverse-engineer, reverse-compile, or translate the Licensed Materials in any manner, except where applicable law expressly prohibits such restrictions. The Client's rights granted herein may not be transferred, leased, recorded or assigned. The License does not include the right to reproduce, distribute or develop derivative works. The License hereby granted does not authorize any hosted work, multi-user license, multi-party access agreement, aggregation or similar purpose for the benefit of any third party.",
      accentureRights:
        "Accenture Audit Rights. Customer will audit Customer's use of the Software annually and must demonstrate to Accenture that Customer's use of the Software complies with the obligations set forth in the then-current agreement and applicable Addenda. In the event that the Client identifies the use of the Software in divergence or impact with the purpose and definition provided for in this Proposal, the Client shall expressly and immediately notify Accenture. In addition, Accenture reserves the right, upon reasonable notice to Customer and during normal business hours, with reasonable confidentiality obligations and without unreasonable interruption of Customer's operations, to audit the use of the Licensed Materials for the purpose of verifying Customer's compliance with the terms of the applicable Agreements.",
      clientContent:
        "Customer Content: Customer (and Customer's licensors, where applicable) retain all right, title, and interest in and to Customer Content. Shares of Customer Content (data and information) can only be done in the same productive environment in which they are located. Customer represents that, during the term of this Agreement and the applicable Proposal, it will obtain all authorizations required under the laws prior to Customer Content being entered or processed by the Platform Services. In particular, the Client declares that it will not share Personal Data with Accenture, understood as any information, even public, that can identify an individual, directly or indirectly. Customer grants Accenture the non-exclusive right to host, store, process and transfer Customer Content for Accenture to provide, as described in the Agreement, the Platform Services and for Customer to use such services as set forth in this Agreement, as well as for Accenture's enhancement of the Platform Services in connection with such use. Accenture agrees to destroy any Customer Content. Accenture may: (i) use usage patterns, trends, statistics and other data derived from use of the Platform Services (but not the Client Content itself) for purposes of developing or improving the Platform Services and other products and services from Accenture, provided they do not involve Personal Data; and (ii) provide information to the Cloud Provider in connection with Customer's use of its Cloud Infrastructure services, subject to the Cloud Provider's confidentiality obligations, in order for the Cloud Provider's use to enhance its cloud infrastructure services, as long as they do not involve Personal Data.",
      host: 'Hosting: The cloud on which the Accenture proprietary platform and algorithm will be hosted is subject to scheduled maintenance outages, unscheduled maintenance and system failures. However, the parties acknowledge that, to the extent that the Internet is not owned or controlled by any specific entity, Accenture cannot guarantee that any End User will be able to access the Services at any time. In addition, there is no guarantee that access to the Services will always be available or will be uninterrupted, and Accenture will not be liable to Customer or any third party for failures in accessibility to the Services, where such failures result from Internet failures and Connectivity issues beyond the Accenture\'s reasonable control; Network or end user failure; Hardware errors or networks, where the hardware and/or network are not owned or controlled by Accenture and are provided as part of the Services; Denial of Service or similar attacks intended to "overwhelm" a website with requests for information, content or responses; Failure of applications, code or interfaces associated with third parties and not controlled by Accenture; Viruses, malware or other deactivation codes that are introduced into the Services; Force majeure event as defined in the Brazilian Civil Code; Use that which exceeds the capacity or bandwidth limits described in this proposal or in technical specifications supplementing this proposal; Or errors caused by the Client Content;'
    },
    misc: {
      inlineMultiUpdateSuccess: 'Items Updated Successfully',
      inlineUpdateSuccess: 'Item Updated Successfully',
      success: 'Success',
      profile: 'Profile',
      settings: 'Settings',
      logout: 'Logout',
      unidentifiedUser: 'Unidentified User!',
      moduleSelected: 'Module Selected!',
      selectAModule: 'Select Module',
      dashboards: 'Dashboards',
      till: 'Till',
      period: 'Period',
      error: 'Error performing action',
      comments: 'Comments',
      others: 'Others',
      registration: 'Registrations',
      deleteSuccess: 'Deletion Sucessfull',
      deleteError: 'Deletion failed',
      emptyOptimizations: 'You have no ongoing tasks'
    },
    general: {
      registration: 'General Registrations',
      logistics: 'Logistics',
      demand: 'Demand',
      several: 'Several',
      origination: 'Origination',
      true: 'Yes',
      false: 'No',
      ibpDashboardMenu: 'Monthly Dashboard Registration',
      weeklyDashboardMenu: 'Weekly Dashboard Registration',
      fullfilledDashboardMenu: 'Fullfilled Dashboard Registration',
      logs: 'Logs',
      itas: 'ITAS'
    },
    validation: {
      validateFieldRequired: 'Field required',
      validateFieldEmail: 'Invalid email',
      phoneIsNotValid: 'This phone number is not valid',
      lengthIsNotValid: '',
      minLengthIsNotValid: '',
      maxLengthIsNotValid: ''
    },
    errors: {
      period: {
        beginHorizonGreaterThanEnd:
          'The end of the period must be later than the beginning',
        overlappingPeriods:
          'The period is overlapping another period {begin} - {end}'
      },
      locality: {
        fillLocality: 'Fill in the latitude and longitude fields',
        fillLatitudeAndLongitude: 'Fill in the location fields'
      },
      unity: {
        invalid: 'Invalid units: {arg}'
      },
      unityConversion: {
        invalid: 'Unregistered unit conversions: {arg}'
      },
      noOpenScenario: 'No open scenario!',
      importError: 'File outside the template'
    },
    actions: {
      create: 'Create',
      back: 'Back',
      cancel: 'Cancel',
      next: 'Next',
      save: 'Save',
      finish: 'Finish',
      close: 'Close',
      yes: 'Yes',
      no: 'No',
      update: 'Update',
      delete: 'Delete'
    },
    pages: {
      stuffingPortalCompanies: {
        stuffingPortalCompaniesUsers: {
          title: 'Users'
        }
      },
      takeUpQualityAndContamination: {
        takeUpQualityAndContaminationTranslation: {
          title: 'Quality and Contamination Translation'
        }
      },
      takeUpCompany: {
        takeUpCompaniesUsers: {
          title: 'Users'
        }
      },
      takeUpQualityAndContaminationInformationContracts: {
        takeUpQualityAndContaminationInformation: {
          title: 'Quality and Contamination Information'
        }
      },
      takeUpReferenceTable: {
        takeUpEvaluationCriteria: {
          title: 'Evaluation Criteria'
        }
      },
      takeUpInformationMaster: {
        takeUpInformation: {
          title: 'Take-Up'
        },
        takeUpInformationSummary: {
          title: 'Summary'
        }
      },
      takeUpReportInformationMaster: {
        takeUpReportInformation: {
          title: 'Take-Up Report Information'
        }
      },
      takeUpPremiumAndDiscountInformation: {
        takeUpPremiumAndDiscountCalculationDetails: {
          title: 'Calculation Details'
        },
        takeUpPremiumAndDiscountInformationSummary: {
          title: 'Summary'
        }
      },
      takeUpLotInformationItas: {
        takeUpLotInformationItasDetail: {
          title: 'Take-Up Report Information'
        }
      },
      takeUpNegotiationInformationMaster: {
        takeUpNegotiationInformationSummary: {
          title: 'Summary'
        },
        takeUpNegotiationInformationCharacteristics: {
          title: 'Characteristics'
        }
      },
      inventoryItas: {
        title: 'ITAS - Inventory'
      },
      takeUpInventoryItas: {
        title: 'Take-Up - ITAS - Inventory'
      },
      purchasesAndSalesItas: {
        title: 'ITAS - Contract (Purchase/Sale)'
      },
      bookingItas: {
        title: 'ITAS - Booking'
      },
      openContractItas: {
        title: 'ITAS - Open Contract'
      },
      notificationUser: {
        title: 'Notifications'
      },
      scoNotification: {
        title: 'Notifications',
        message: 'Message',
        notificationDate: 'Notification Date',
        notificationEvent: 'Event',
        notificationModule: 'Module',
        notificationType: 'Type',
        readDate: 'Read in',
        isRead: 'Read'
      },
      optimizationQueueEntity: {
        title: 'Tasks in progress',
        operation: 'Type',
        scenarioName: 'Scenario',
        status: 'Status',
        startDate: 'Submitted in',
        startOptimization: 'Started in',
        endDate: 'Finished in',
        duration: 'Duration',
        optimization: 'Optimization',
        showAllOngoingTasks: 'See all tasks in progress',
        stopOptimization: 'Stop Optimization',
        exportLogs: 'Export Logs',
        visualize: 'Visualize',
        updateOn: 'Update on',
        requestingUserName: 'Requested by',
        round: 'Round',
        percentageAttendance: '% Scenario Attendance',
        pd: 'P/D',
        demandAttendedIM: 'Demand Attended IM',
        demandAttendedEM: 'Demand Attended EM'
      },
      optimization: {
        title: 'Optimization',
        parameters: 'Optimization parameters',
        validateInput: 'Validate Input',
        optimization: 'Optimization',
        scenarioName: 'Scenario Name',
        period: 'Period',
        type: 'Type',
        objectiveFunction: 'Objective Function',
        maxProfit: 'Max Profit',
        minCosts: 'Min Costs',
        penaltyWeight: 'Penalty Weight',
        generalParameters: 'General Parameters',
        externalMarket: 'External Market',
        internalMarket: 'Internal Market',
        monthly: 'Monthly',
        weekly: 'Weekly',
        till: 'Till',
        exportPenalty: 'Penalty for export outside the deadline (ICMS)',
        boardingDate: 'Export shipment date',
        clientArrivingDate: 'Date Arrived at Client',
        riskySales: 'Risk Sales',
        vipClients: 'Vip Clients',
        creditCardDate: 'Credit Card Date',
        productAvailability: 'Product Availability',
        contractMargin: 'Contract Margin',
        startOptimization: 'Start Optimization',
        loadingOptimization: 'Optimizing Scenario',
        backgroundOptimization: 'Background Optimization',
        cancelOptimization: 'Cancel Optimization',
        askCancelOptimization: 'Do you want to cancel the optimization?',
        cancelDescription:
          'When canceling the optimization all data will be discarded!',
        scenarioQueued: 'Scenario successfully submitted for optimization',
        scenarioQueuedDescription:
          'The optimization will be performed in the background and when finished an informational message will be sent',
        onGoingTasks: 'Tasks in progress',
        homepage: 'Go to home page',
        requestToStopOptimizationSent: 'Request to stop optimization sent',
        couldNotRequestOptimizationStop: 'Could not request optimization stop',
        finishedWithError:
          'Optimization from scenario {scenario} finished with error!',
        finishedSuccessfully:
          'Optimization from scenario {scenario} completed successfully!',
        interruptedSuccessfully:
          'Optimization from scenario {scenario} interrupted successfully!'
      },
      scenarioParameters: {
        title: 'Parameters'
      },
      ibpWarehouseCapacityUtilizationDashboard: {
        title:
          'Warehouse capacity utilization projection and stuffing terminal',
        operationType: 'Operation Type',
        centerCode: 'Center',
        modalCode: 'Modal',
        planningMonth: 'Planning Month',
        plannedCapacity: 'Planned Capacity',
        plannedCapacityUnit: 'Planned Capacity Unit',
        operationCapacity: 'Operation Capacity',
        operationCapacityUnit: 'Operation Capacity Unit'
      },
      ibpCarrierCapacityUtilizationDashboard: {
        title: 'Carrier Capacity Utilization',
        carrierName: 'Carrier Name',
        planningMonth: 'Planning Month',
        originPort: 'Origin Port',
        destinationRegion: 'Destination Region',
        destinationCountry: 'Destination Country',
        plannedCapacity: 'Planned Volume',
        plannedCapacityUnit: 'Planned Volume Unit',
        carrierCapacity: 'Contracted Volume',
        carrierCapacityUnit: 'Contracted Volume Unit'
      },
      performanceDashboard: {
        title: 'Performance',
        unityMeasure: 'Unit',
        filter: 'Filter',
        ton: 'Metric Ton (MT)',
        highCube: 'Container (Cntr)',
        monthly: 'Monthly',
        weekly: 'Weekly',
        plan: 'Plan'
      },
      monthlyPlanningDashboardRevised: {
        title: 'Monthly Planning',
        headerTitle: 'Monthly Planning (IBP)',
        horizon: 'Horizon',
        unityMeasure: 'Unit',
        selectedPeriod: 'Selected Period',
        ton: 'Metric Ton (MT)',
        highCube: 'Container (Cntr)'
      },
      weeklyPlanningDashboard: {
        title: 'Weekly Planning',
        headerTitle: 'Weekly Planning',
        unityMeasure: 'Unit',
        details: 'Details',
        filter: 'Filter',
        ton: 'Metric Ton (MT)',
        highCube: 'Container (Cntr)'
      },
      booking: {
        title: 'Bookings',
        bookingRef: 'Booking Ref #',
        code: 'Booking',
        originPort: 'Origin Port',
        departureTerminal: 'Departure Terminal',
        destinationPort: 'Destination Port',
        trip: 'Trip',
        ship: 'Ship',
        draftDeadline: 'Draft Deadline',
        projection: 'Projection',
        containerQuantity: 'Container Quantity',
        costByContainer: 'Cost By Container',
        costByContainerUnit: 'Unit Measure - Cost By Container',
        carrierId: 'Carrier',
        startLoadingDate: 'Free Time Start',
        endLoadingDate: 'CargoDeadline',
        originDepartureDate: 'ETS',
        destinationArrivalDate: 'ETA',
        active: 'Active',
        carrier: 'Carrier',
        transshipment: 'Transshipment'
      },
      cargillTeam: {
        title: 'Cargill Team'
      },
      controlTower: {
        title: 'Control Tower'
      },
      integratedPlanning: {
        title: 'Integrated Planning'
      },
      workingHours: {
        title: 'Operation Days',
        locality: 'Locality',
        region: 'Region',
        country: 'Country',
        state: 'State',
        city: 'City',
        center: 'Center',
        centerType: 'Center type',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        weekBegin: 'Week Begin',
        weekEnd: 'Week End',
        active: 'Active'
      },
      center: {
        centers: 'Centers',
        title: 'Centers',
        locality: 'Locality',
        code: 'Code',
        description: 'Description',
        centerType: 'Center Type',
        active: 'Active',
        region: 'Region',
        subRegion: 'Sub-Regional',
        country: 'Country',
        city: 'City',
        longitude: 'Longitude',
        latitude: 'Latitude',
        state: 'State',
        port: 'Port',
        roadMap: 'Road Map'
      },
      centerInformation: {
        title: 'Warehouse and Farm Capacities',
        active: 'Active',
        beginHorizon: 'Begin',
        center: 'Center',
        centerType: 'Center Type',
        endHorizon: 'End',
        modal: 'Modal',
        modalType: 'Modal Type',
        receivingCapacity: 'Cap.Receiv',
        receivingShippingCapacityUnity: 'Unity.Receiv/Ship',
        shippingCapacity: 'Cap.Ship',
        storageCapacity: 'Cap.Storage',
        storageCapacityUnity: 'Unity.Storage',
        centerAvailableCapacity: {
          title: 'Center Available Capacity',
          centerInformation: 'Loading Capacity',
          referenceDate: 'Date',
          receivingUsedCapacity: 'Cap.Receiv Used',
          receivingUsedCapacityUnity: 'Unity.Receiv Used',
          azUsedCapacity: 'Cap.Az Used',
          azUsedCapacityUnity: 'Unity.Az Used',
          expedUsedCapacity: 'Cap.Exped Used',
          expedUsedCapacityUnity: 'Unity.Exped Used',
          receivingAvailableCapacity: 'Cap.Receiv Available',
          receivingAvailableCapacityUnity: 'Unity.Receiv Available',
          azAvailableCapacity: 'Cap.Az Available',
          azAvailableCapacityUnity: 'Unity.Az Available',
          expedAvailableCapacity: 'Cap.Exped Available',
          expedAvailableCapacityUnity: 'Unity.Exped Available',
          itasPeriodStartDate: 'Date',
          itasPeriodEndDate: 'Date',
          lastItasUpdatedDate: 'Date'
        }
      },
      locality: {
        title: 'Locality',
        code: 'Code',
        description: 'Description',
        region: 'Region',
        country: 'Country',
        state: 'State',
        city: 'City',
        street: 'Street',
        district: 'District',
        number: 'Number',
        complement: 'Complement',
        latitude: 'Latitude',
        longitude: 'Longitude',
        active: 'Active'
      },
      home: {
        title: 'Home',
        back: 'Back',
        cancel: 'Cancel',
        next: 'Next'
      },
      security: {
        title: 'Security'
      },
      transactions: {
        title: 'Transactions'
      },
      users: {
        title: 'Users'
      },
      sample: {
        title: 'Sample',
        code: 'Code',
        name: 'Name',
        description: 'Description',
        sampleGroup: 'Sample Group'
      },
      sampleGroup: {
        title: 'Sample Group',
        code: 'Code',
        name: 'Name'
      },
      scenarioCargill: {
        title: 'Scenario',
        scenarios: 'Scenarios',
        name: 'Code',
        description: 'Description',
        createdDate: 'Created Date',
        updateDate: 'Update Date',
        startDate: 'Start Date',
        endDate: 'End Date',
        lastUpdatedDate: 'Last Updated Date',
        lastOptimizationDate: 'Last Optimization Date',
        createdByName: 'Created By',
        lastUpdatedByName: 'Last Updated By',
        lastOptimizedByName: 'Last Optimized By',
        public: 'Public',
        scenarioPublic: 'Scenario Public',
        private: 'Private',
        optimized: 'Optimized',
        type: 'Type',
        beginHorizon: 'Begin Horizon',
        endHorizon: 'End Horizon',
        planningHorizon: 'Planning Horizon',
        originalScenarioCreatedDate: 'Original scenario creation date',
        originalScenarioName: 'Original scenario name',
        horizon: 'Date and Horizon',
        createScenario: 'Create Scenario',
        scenarioIsBeingCreated: 'Scenario is being created',
        scenarioIsBeingCreatedDescription:
          'The creation of the scenario will be carried out in the background and when finished a notification will be sent',
        scenarioIsBeingImported: 'Scenario is being imported',
        cenarioIsBeingImportedDescription:
          'The scenario will be imported in the background and a notification will be sent when finished',
        scenarioName: 'Scenario Name',
        start: 'Start',
        end: 'End',
        blank: 'Blank',
        copy: 'Copy',
        master: 'Master',
        dataSource: 'Data Source',
        selectSource: 'Select Data Source',
        newScenario: 'New Scenario',
        publish: 'Publish',
        openScenario: 'Open Scenario',
        confirmPublishTitle: 'Publish scenario',
        published: 'published',
        confirmPublish:
          'Do you want to publish this scenario? Data will be disabled for change. Any published scenario that is from the same type and has the same horizon will be unpublished.',
        scenarioNotFoundToPublish:
          'The scenario was not found to be published.',
        scenarioNotOptimizedToPublish:
          'The scenario needs to be optimized to be published.',
        permissionDeniedToPublish:
          "You don't have permission to publish this scenario.",
        unpublish: 'Unpublish',
        confirmUnpublishTitle: 'Unpublish the scenario',
        confirmUnpublish:
          'Do you want to unpublish this scenario? The data can be changed again.',
        scenarioNotFoundToUnpublish: 'The scenario was not found to unpublish.',
        permissionDeniedToUnpublish:
          'You are not allowed to unpublish this scenario',
        scenarioNotFoundToPublic: 'The scenario was not found to make public',
        permissionDeniedToPublic:
          'You are not allowed to change field public of this scenario',
        permissionDeniedToDelete: 'You are not allowed to delete this scenario',
        scenarioNotFoundToDelete: 'The scenario was not found to be deleted.',
        importTitle: 'Import Scenario',
        export: 'Export',
        import: 'Import',
        importError:
          'Scenario import error. Contact your system administrator.',
        importSelectDescription: 'Drag file to start import',
        selectedScenario: 'Selected Scenario',
        showAllScenarios: 'Show All Scenarios',
        viewAllScenarios: 'View All Scenarios',
        closeScenario: 'Close Scenario',
        confirmOpenScenario: 'Do you want to open the scenario {scenario}?',
        confirmCloseScenario: 'Do you want to close the scenario {scenario}?',
        scenarioWillBeClosed:
          'When opening the scenario, the scenario {scenario} will automatically close.',
        scenarioClose:
          'When closing the scenario, the Master Data scenario will automatically be opened.',
        notShowAgain: 'Do Not Show Again',
        identifyScenario: 'Enter the scenario ID and proceed to create it.',
        confirmScenarioData: 'Confirm Scenario Data',
        createdSuccessfully: 'Scenario {scenario} created successfully',
        errorOnCreated: 'Could not create scenario {scenario}',
        redirecting:
          'You will be redirected to scenario list in {seconds} seconds...',
        askAction: 'What do you want to do?',
        scenarioList: 'Scenario List',
        createNewScenario: 'Create New Scenario',
        monthly: 'Monthly',
        weekly: 'Weekly',
        masterData: 'MasterData',
        confirmation: 'Confirmation',
        exportDateScenario: 'Export of all exportable input data',
        selectMonthlyDate: 'Reference To Horizon Start',
        MonthlyM1: 'M +1',
        MonthlyM2: 'M +2',
        scenarioPublishingMessage:
          'Publication request completed successfully. A notification will be sent upon completion.'
      },
      importLog: {
        title: 'Import Log',
        date: 'Date',
        onlyDate: 'Date',
        hour: 'Hour',
        user: 'User',
        entity: 'Page',
        scenario: 'Scenario',
        insertedRows: 'Inserted rows',
        updatedRows: 'Updated rows',
        errorRows: 'Error rows',
        actions: 'Actions',
        downloadLog: 'Download log'
      },
      material: {
        title: 'Cotton Type',
        code: 'Code',
        description: 'Description',
        active: 'Active'
      },
      unity: {
        title: 'Unit',
        code: 'Code',
        symbol: 'Symbol',
        description: 'Description',
        category: 'Category'
      },
      unityConversion: {
        title: 'Unit Conversion',
        beginHorizon: 'Begin Horizon',
        endHorizon: 'End Horizon',
        fromUnity: 'From Unit',
        toUnity: 'To Unit',
        active: 'Active',
        factor: 'Factor',
        errors: {
          sameUnits: 'It is not possible to convert between the same unit'
        },
        hints: {
          factor:
            'The conversion factor is a multiplication in relation to the FROM unit'
        }
      },
      optimizationParameters: {
        title: 'Optimization Parameters'
      },
      modal: {
        title: 'Modal',
        code: 'Code',
        description: 'Description',
        modalType: 'Modal Type',
        active: 'Active'
      },
      workException: {
        title: 'Holidays and Stops',
        beginHorizon: 'Begin Horizon',
        endHorizon: 'End Horizon',
        region: 'Region',
        country: 'Country',
        city: 'City',
        state: 'State',
        center: 'Center',
        centerType: 'Center Type',
        exceptionDate: 'Exception Date',
        active: 'Active',
        numberOfDays: 'Number Of Days'
      },
      customerCargill: {
        title: 'Customer',
        code: 'Code',
        description: 'Description',
        vip: 'VIP',
        region: 'Region',
        country: 'Country',
        state: 'State',
        city: 'City',
        longitude: 'Longitude',
        latitude: 'Latitude',
        marketType: 'Market Type',
        active: 'Active',
        customerCargillReceivingCapacity: {
          title: 'Nominal Customer Receiving Capacity',
          customer: 'Customer',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          receivingCapacity: 'Cap.Receiv',
          receivingCapacityUnit: 'Unity.Receiv',
          active: 'Active'
        },
        customerCargillAvailableCapacity: {
          title: 'Available Customer Receiving Capacity',
          customer: 'Customer',
          customerReceivingCapacity: 'Nominal Customer Receiving Capacity',
          referenceDate: 'Date',
          receivingUsedCapacity: 'Cap.Receiv Used',
          receivingUsedCapacityUnity: 'Unity.Receiv Used',
          receivingAvailableCapacity: 'Cap.Receiv Available',
          receivingAvailableCapacityUnity: 'Unity.Receiv Available'
        }
      },
      centerCost: {
        title: 'Interior Warehouse',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        centerType: 'Center Type',
        center: 'Center',
        material: 'Cotton Type',
        modalType: 'Modal Type',
        modal: 'Modal',
        fixedCost: 'Fixed Cost',
        fixedCostUnity: 'Fixed Cost Unit',
        receivingCost: 'Receiving Cost',
        receivingCostUnity: 'Receiving Cost Unit',
        storageCost: 'Storage Cost',
        storageCostUnity: 'Storage Cost Unit',
        shippingCost: 'Shipping Cost',
        shippingCostUnity: 'Shipping Cost Unit',
        active: 'Active'
      },
      stretch: {
        title: 'Bogey List and Stretchs',
        centerOrigin: 'Warehouse',
        centerTypeOrigin: 'Center Type Origin',
        regionOrigin: 'Region Origin',
        countryOrigin: 'Country Origin',
        stateOrigin: 'P Part 4',
        cityOrigin: 'City Origin',
        centerDestination: 'Center Destination',
        centerTypeDestination: 'Center Type Destination',
        regionDestination: 'Region Destination',
        countryDestination: 'Country Destination',
        stateDestination: 'UF Destination',
        cityDestination: 'City Destination',
        modal: 'Modal',
        modalType: 'Modal Type',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        freightCost: 'Freight Cost',
        freightCostUnity: 'Freight Cost Unit',
        transitTime: 'Transit Time',
        incoterm: 'Incoterm',
        operationType: 'Operation Type',
        active: 'Active',
        customer: 'Customer',
        port: 'Brazil Port',
        errors: {
          atLeastOneGroupDestinationLocation:
            'At least one destination location group must be filled in <br>Group 1 - (Country Destination - State Destination - City Destination), <br>Group 2 - (Center Destination, Center Type Destination), <br>Group 3 - (Customer), <br>Group 4 - (Brazil Port)',
          onlyOneGroupDestinationLocation:
            'Only one destination location group must be filled in <br>Group 1 - (Country Destination - State Destination - City Destination), <br>Group 2 - (Center Destination, Center Type Destination), <br>Group 3 - (Customer), <br>Group 4 - (Brazil Port)',
          atLeastOneGroupOriginLocation:
            'At least one origin location group must be filled in <br>Group 1 - (Country Origin - State Origin - City Origin), <br>Group 2 - (Center Origin, Center Type Origin)',
          onlyOneGroupOriginLocation:
            'Only one origin location group must be filled in <br>Group 1 - (Country Origin - State Origin - City Origin), <br>Group 2 - (Center Origin, Center Type Origin)'
        }
      },
      modalInformation: {
        title: 'Modal Capability',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        modalType: 'Modal Type',
        modal: 'Modal',
        capacity: 'Capacity',
        capacityUnity: 'Capacity Unit',
        active: 'Active',
        isTakeorPay: 'Take or Pay'
      },
      portStuffingTerminal: {
        title: 'Stuffing Terminal',
        terminalCenterType: 'Terminal Type',
        terminalCenter: 'Terminal',
        leadTimeDays: 'Lead Time (Days)',
        freeTimeDays: 'Free Time (Days)',
        isTakeOrPay: 'Take or Pay',
        active: 'Active',
        portStuffingTerminalInformation: {
          title: 'Stuffing Terminal Nominal Capacity',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          portStuffingTerminal: 'Stuffing Terminal',
          stuffingCapacity: 'Cap.Stuffing',
          stuffingCapacityUnity: 'Unity.Stuffing',
          active: 'Active'
        },
        portStuffingTerminalCost: {
          title: 'Stuffing Terminal Costs',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          portStuffingTerminal: 'Stuffing Terminal',
          stuffingCost: 'Stuffing Cost',
          stuffingCostUnity: 'Stuffing Cost Unit',
          active: 'Active'
        },
        portStuffingTerminalAdditionalInformation: {
          title: 'Stuffing Terminal Additional Information',
          portStuffingTerminal: 'Stuffing Terminal',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          destinationPort: 'Destination Port',
          transitTime: 'Transit Time',
          transferTerminal: 'Transfer Terminal',
          active: 'Active'
        },
        portStuffingTerminalAvailableCapacity: {
          title: 'Stuffing Terminal Available Capacity',
          portStuffingTerminal: 'Stuffing Terminal',
          portStuffingTerminalInformation: 'Port Stuffing Terminal Information',
          referenceDate: 'Date',
          stuffingUsedCapacity: 'Cap.Stuff Used',
          stuffingUsedCapacityUnity: 'Unity.Stuff Used',
          stuffingAvailableCapacity: 'Cap.Stuff Available',
          stuffingAvailableCapacityUnity: 'Unity.Stuff Available'
        },
        portStuffingTerminalVolumeTakeOrPay: {
          title: 'Volume Take or Pay',
          portStuffingTerminal: 'Stuffing Terminal',
          begin: 'Begin',
          end: 'End',
          volumeTakeOrPay: 'Volume Take Or Pay',
          volumeTakeOrPayUnity: 'Unit of measurement - Volume Take or Pay',
          active: 'Active'
        },
        portStuffingTerminalTakeOrPayCost: {
          title: 'Take or Pay Costs',
          portStuffingTerminal: 'Stuffing Terminal',
          begin: 'Begin',
          end: 'End',
          takeOrPayCost: 'Take or Pay Cost',
          takeOrPayCostUnity: 'Unit of measurement - Volume Take or Pay',
          active: 'Active'
        },
        portStuffingTerminalFreeTimeLogisticPortal: {
          title: 'Free Time Logistic Portal',
          portStuffingTerminal: 'Stuffing Terminal',
          beginHorizon: 'Begin Horizon',
          endHorizon: 'End Horizon',
          freeTimeDays: 'Free Time (Days)',
          condition: 'Condition',
          active: 'Active'
        }
      },
      carrier: {
        title: 'Carrier',
        code: 'Code',
        description: 'Description',
        nationalRegistry: 'National Registry',
        active: 'Active',
        carrierFreeTimeInformation: {
          title: 'Carrier Free Time Information',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          carrier: 'Carrier',
          originPort: 'Origin Port',
          portStuffingTerminal: 'Stuffing Terminal',
          subRegion: 'Sub-Regional',
          state: 'State',
          city: 'City',
          freeTime: 'Free Time',
          active: 'Active'
        },
        carrierCostAndTransitTimeInformation: {
          title: 'Cost and Transit Time',
          beginHorizon: 'Begin Period',
          endHorizon: 'End Period',
          carrier: 'Carrier',
          originPort: 'Origin Port',
          destinationPort: 'Destination Port',
          shippingCost: 'Cost',
          shippingCostUnity: 'Unit - Cost',
          transitTime: 'Transit Time',
          active: 'Active'
        },
        carrierCapacityInformation: {
          title: 'Shipping Capacity',
          beginHorizon: 'Begin Period',
          endHorizon: 'End Period',
          carrier: 'Carrier',
          originPort: 'Origin Port',
          region: 'Destination Region',
          shippingCapacity: 'Shipping Capacity',
          shippingCapacityUnity: 'Unit - Shipping Capacity',
          active: 'Active'
        },
        carrierTerminalRestriction: {
          title: 'Stuffing Terminal Restriction',
          carrier: 'Carrier',
          beginHorizon: 'Begin Period',
          endHorizon: 'End Period',
          stuffingTerminal: 'Stuffing Terminal'
        }
      },
      impurityGreatness: {
        title: 'Contaminations',
        code: 'Code',
        description: 'Description'
      },
      optimizationConstraintMinimumWithdrawal: {
        title: 'Minimum Withdrawal',
        centerTypeId: 'Center Type',
        centerId: 'Center',
        modalTypeId: 'Modal Type',
        modalId: 'Modal',
        minimumWithdrawal: 'Minimum Withdrawal',
        minimumWithdrawalUnityId: 'Unit - Minimum Withdrawal',
        active: 'Active',
        centerType: 'Center Type',
        center: 'Center',
        modalType: 'Modal Type',
        modal: 'Modal',
        minimumWithdrawalUnity: 'Unit - Minimum Withdrawal'
      },
      qualityGreatness: {
        title: 'HVI Units',
        code: 'Code',
        description: 'Description',
        spreadReference: 'Reference for quality match'
      },
      purchaseContract: {
        title: 'Purchase Contract',
        company: 'Company',
        contractReference: 'Contract Reference',
        cropYear: 'Crop Year',
        marketType: 'Market Type',
        material: 'Cotton Type',
        quantity: 'Quantity',
        quantityUnity: 'Quantity Unit',
        center: 'Counterparty Code',
        counterPartyName: 'Counterparty Name',
        incoterm: 'Incoterm',
        counterPartySector: 'Counterparty Sector',
        contractPrice: 'Contract Price',
        contractPriceUnityCode: 'Contract Price Unit',
        contractDate: 'Contract Date',
        positionMonth: 'Position Month',
        positionMonthProjection: 'Position Month Projection',
        origin: 'Warehouse',
        producer: 'Counterparty Ref',
        uf: 'UF',
        beginShipPeriod: 'Begin Ship Period',
        endShipPeriod: 'End Ship Period',
        contractPricePoints: 'P/D',
        contractPricePointsUnity: 'Unit - P/D',
        isContractProjection: 'Projection',
        hasInventory: 'Inventory',
        active: 'Active',
        invoicedQuantity: 'Invoiced Quantity',
        usBucketProjectionOption1: 'US Bucket Projection (option 1)',
        usBucketProjectionOption2: 'US Bucket Projection (option 2)',
        usBucketProjectionOption3: 'US Bucket Projection (option 3)',
        usBucketProjectionOption4: 'US Bucket Projection (option 4)',
        usBucketProjectionOption5: 'US Bucket Projection (option 5)',
        usBucketProjectionOption6: 'US Bucket Projection (option 6)',
        usBucketProjectionOption7: 'US Bucket Projection (option 7)',
        usBucketProjectionOption8: 'US Bucket Projection (option 8)',
        volumeProjectionOption1: 'Volume Projection (option 1)',
        volumeProjectionOption2: 'Volume Projection (option 2)',
        volumeProjectionOption3: 'Volume Projection (option 3)',
        volumeProjectionOption4: 'Volume Projection (option 4)',
        volumeProjectionOption5: 'Volume Projection (option 5)',
        volumeProjectionOption6: 'Volume Projection (option 6)',
        volumeProjectionOption7: 'Volume Projection (option 7)',
        volumeProjectionOption8: 'Volume Projection (option 8)',
        volumeProjection: 'Volume Projection (total)',
        check: 'Check',
        part5: 'Part5',
        lastItasUpdatedDate: 'Last Update ITAS',
        purchaseContractQualitySpecification: {
          title: 'Quality Specification',
          purchaseContract: 'Purchase Contract',
          qualityGreatness: 'HVI Units',
          minValue: 'Min Value',
          maxValue: 'Max Value',
          avgValue: 'Average Value'
        }
      },
      purchaseContractQualitySpecification: {
        title: 'Quality Specification'
      },
      flowProhibition: {
        title: 'Flow Restrictions',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        customerVIP: 'Customer VIP',
        active: 'Active',
        purchaseContract: 'Purchase Contract - Split',
        purchaseContractCompany: 'Purchase Contract - Company',
        purchaseContractCropYear: 'Purchase Contract - Crop Year',
        purchaseContractCak: 'Purchase Contract - CAK',
        modalType: 'Modal Type',
        modal: 'Modal',
        centerTypeOrigin: 'Center Type Origin',
        centerOrigin: 'Warehouse',
        regionOrigin: 'Region Origin',
        countryOrigin: 'Country Origin',
        stateOrigin: 'P Part 4',
        cityOrigin: 'City Origin',
        centerTypeDestination: 'Center Type Destination',
        centerDestination: 'Center Destination',
        regionDestination: 'Region Destination',
        countryDestination: 'Country Destination',
        stateDestination: 'UF Destination',
        cityDestination: 'City Destination',
        customer: 'Customer',
        incoterm: 'Incoterm',
        errors: {
          atLeastOneGroupDestinationLocation:
            'At least one destination location group must be filled in <br> Group 1 - (Destination Region - Destination Country - Destination State - Destination City) <br> Group 2 - (Destination Center type, Destination Center)',
          onlyOneGroupDestinationLocation:
            'Only one destination location group must be filled in <br> Group 1 - (Destination Region - Destination Country - Destination State - Destination City) <br> Group 2 - (Destination Center type, Destination Center)',
          atLeastOneGroupOriginLocation:
            'At least one origin location group must be filled in <br> Group 1 - (Origin Region - Origin Country - Origin State - Origin City) <br> Group 2 - (Origin Center type, Origin Center)',
          onlyOneGroupOriginLocation:
            'Only one origin location group must be filled in <br> Group 1 - (origin Region - origin Country - origin State - origin City) <br> Group 2 - (origin Center type, origin Center)',
          onlyOneCenterOrCustomer:
            'Origin and Client Center cannot be registered simultaneously'
        }
      },
      limitedDaysWithoutCharge: {
        title: 'ICMS Deadline',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        region: 'Region',
        country: 'Country',
        state: 'P Part 4',
        limitedDays: 'Limited Days',
        active: 'Active',
        errors: {
          requiredLocality:
            'At least one location information must be filled in <br> (Region - Country - State)'
        }
      },
      saleContract: {
        title: 'Sale Contract',
        company: 'Company',
        contractReference: 'Contract Reference',
        cropYear: 'Crop Year',
        marketType: 'Market Type',
        material: 'Cotton Type',
        quantity: 'Quantity',
        quantityUnity: 'Quantity Unit',
        customer: 'Customer',
        destination: 'Destination',
        port: 'Destination Port',
        country: 'Country',
        incoterm: 'Incoterm',
        counterPartySector: 'Counterparty Sector',
        contractPrice: 'Contract Price',
        contractPriceUnity: 'Unit - Contract Price',
        contractDate: 'Contract Date',
        positionMonth: 'Position Month',
        positionMonthProjection: 'Position Month Projection',
        beginShipPeriod: 'Begin Ship Period',
        endShipPeriod: 'End Ship Period',
        cakLSD: 'Cak LSD',
        minPercentageQuantityTolerance: 'Min Percentage Quantity Tolerance',
        maxPercentageQuantityTolerance: 'Max Percentage Quantity Tolerance',
        isContractProjection: 'Projection',
        active: 'Active',
        isRiskySale: 'Risky Sale',
        biDate: 'BI Date',
        partialAllowed: 'Allow solver to define/suggest service installments',
        usBucket: 'US Bucket',
        penaltyType: 'Penalty Type',
        customerReceiptExpectation: 'Customer Receipt Expectation',
        penaltyAmountPercentage: 'Penalty Amount (%)',
        status: 'Status',
        invoicedQuantity: 'Invoiced Quantity',
        saleContractPremiumAndDiscount: 'SaleContract Premium And Discount',
        part5: 'Part5',
        lastItasUpdatedDate: 'Last Update ITAS',
        hints: {
          partialAllowed:
            'It must be used in cases where the contract allows partial delivery, however, without a defined rule.'
        }
      },
      usBucket: {
        title: 'US Bucket and PD',
        code: 'US Bucket Code',
        description: 'Description',
        active: 'Active',
        usBucketPD: {
          title: 'Premium and Discount',
          pdPurchase: 'P/D Purchase',
          pdPurchaseUnity: 'Unit - P/D Purchase',
          pdSale: 'P/D Sale',
          pdSaleUnity: 'Unit - P/D Sale',
          harvest: 'Crop Year',
          active: 'Active',
          usBucket: 'US Bucket'
        },
        usBucketCountry: {
          title: 'US Bucket Country',
          country: 'Country',
          maxLimit: 'Maximum Limit Acceptable',
          active: 'Active',
          usBucket: 'US Bucket'
        }
      },
      usBucketPD: {
        title: 'Premium and Discount'
      },
      usBucketCountry: {
        title: 'US Bucket Country'
      },
      saleContractPremiumAndDiscount: {
        title: 'Application and PD',
        company: 'Company',
        contractReference: 'Contract',
        premiumDiscount: 'P/D',
        premiumDiscountUnity: 'Unit - P/D',
        flexibilityToExceedMaxApplication:
          'Flexibility for thickness % Max Application (Quality and Type)',
        saleContractPremiumAndDiscountUSBucketAplication: {
          title: 'US Bucket Aplication',
          usBucket: 'US Bucket',
          maxPercentage: 'Maximum Percentage',
          saleContractPremiumAndDiscount: 'Application and PD',
          active: 'Active'
        },
        saleContractPremiumAndDiscountTypeAplication: {
          title: 'Type Aplication',
          material: 'Cotton Type',
          maxPercentage: 'Maximum Percentage',
          saleContractPremiumAndDiscount: 'Application and PD',
          active: 'Active'
        },
        saleContractPremiumAndDiscountQualityAplication: {
          title: 'Quality Aplication',
          qualityGreatness: 'HVI Units',
          minValue: 'Min Value',
          maxValue: 'Max Value',
          lowerAvgValue: 'Lower Average Value',
          upperAvgValue: 'Upper Average Value',
          maxPercentage: 'Maximum Percentage',
          saleContractPremiumAndDiscount: 'Application and PD',
          active: 'Active'
        },
        saleContractPremiumAndDiscountImpurityAplication: {
          title: 'Permitted Degree of Impurity',
          impurityGreatness: 'Contaminations',
          maxValue: 'Max Level',
          saleContractPremiumAndDiscount: 'Application and PD',
          active: 'Active'
        }
      },
      saleContractPremiumAndDiscountUSBucketAplication: {
        title: 'US Bucket Aplication'
      },
      saleContractPremiumAndDiscountTypeAplication: {
        title: 'Type Aplication'
      },
      saleContractPremiumAndDiscountQualityAplication: {
        title: 'Quality Aplication'
      },
      saleContractPremiumAndDiscountImpurityAplication: {
        title: 'Permitted Degree of Impurity'
      },
      configurationArrivalWindow: {
        title: 'Discharge Window',
        limitInfBooking: 'Limit Inf. Ctnr Booking',
        limitSupBooking: 'Limit Sup. Ctnr Booking',
        breakDays: 'Break days'
      },
      demandFulfillmentProhibition: {
        title: 'Allocation Constraints',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        customerVIP: 'Customer VIP',
        active: 'Active',
        purchaseContractCompany: 'Purchase Contract - Company',
        purchaseContractCropYear: 'Purchase Contract - Crop Year',
        purchaseContract: 'Purchase Contract - Split',
        purchaseContractCak: 'Purchase Contract - CAK',
        centerTypeOrigin: 'Center Type Origin',
        centerOrigin: 'Warehouse',
        centerTypeDestination: 'Center Type Destination',
        centerDestination: 'Center Destination',
        saleContractCompany: 'Sale Contract - Company',
        saleContractCropYear: 'Sale Contract - Crop Year',
        saleContract: 'Sale Contract - Split',
        saleContractCak: 'Sale Contract - CAK',
        baleType: 'Bale Type',
        customer: 'Customer',
        carrier: 'Carrier',
        regionOrigin: 'Region Origin',
        countryOrigin: 'Country Origin',
        stateOrigin: 'P Part 4',
        cityOrigin: 'City Origin',
        regionDestination: 'Region Destination',
        countryDestination: 'Country Destination',
        stateDestination: 'State Destination',
        cityDestination: 'City Destination',
        errors: {
          atLeastOneGroupDestinationLocation:
            'At least one destination location group must be filled in <br> Group 1 - (Destination Region - Destination Country - Destination State - Destination City) Group 2 - (Customer VIP - Customer) <br> Group 3 - (Sale Contract)',
          onlyOneGroupDestinationLocation:
            'Only one destination location group must be filled in <br> Group 1 - (Destination Region - Destination Country - Destination State - Destination City) Group 2 - (Customer VIP - Customer) <br> Group 3 - (Sale Contract)',
          atLeastOneGroupOriginLocation:
            'At least one origin location group must be filled in <br> Group 1 - (Origin Region - Origin Country - Origin State - Origin City) <br> Group 2 - (Origin Center type, Origin Center) <br> Group 3 - (Purchase Contract) <br> Group 4 - (Carrier)',
          onlyOneGroupOriginLocation:
            'Only one origin location group must be filled in <br> Group 1 - (origin Region - origin Country - origin State - origin City) <br> Group 2 - (origin Center type, origin Center) <br> Group 3 - (Purchase Contract) <br> Group 4 - (Carrier)'
        }
      },
      inventory: {
        title: 'Inventory',
        purchaseContract: 'Reference Code',
        lotReference: 'Lot Ref',
        warehouse: 'Warehouse',
        ukGin: 'UK Gin',
        usBucket: 'US Bucket',
        material: 'Cotton Type',
        weight: 'Weight',
        weightUnit: 'Unit - Weight',
        marketType: 'Market Type',
        counterPartySector: 'Counterparty Sector',
        balesQuantity: 'Bales Quantity',
        balesPerContainer: 'Bales Per Container',
        invoiceDate: 'Invoice Date',
        originState: 'Origin State',
        contractPricePoints: 'P/D',
        contractPricePointsUnit: 'Unit - P/D',
        availabilityDate: 'Availability Date',
        active: 'Active',
        lotId: 'Lot ID',
        mark: 'Mark',
        part4: 'Part4',
        part5: 'Part5',
        lastItasUpdatedDate: 'Last Update ITAS',
        purchaseContractCompany: 'Purchase Contract - Company',
        purchaseContractCropYear: 'Purchase Contract - Crop Year',
        purchaseContractReference: 'Purchase Contract - Reference',
        inventoryQualitySpecification: {
          title: 'Quality Specification',
          inventory: 'Inventory',
          qualityGreatness: 'HVI Units',
          minValue: 'Minimum value',
          avgValue: 'Average Value',
          maxValue: 'Maximum Value',
          infValue: 'Inf',
          supValue: 'Sup'
        },
        inventoryImpuritySpecification: {
          title: 'Impurity Specification',
          inventory: 'Inventory',
          impurityGreatness: 'Contaminations',
          maxValue: 'Level'
        }
      },
      inventoryQualitySpecification: {
        title: 'Quality Specification'
      },
      inventoryImpuritySpecification: {
        title: 'Impurity Specification'
      },
      incoterm: {
        title: 'Incoterm Configuration',
        type: 'Code',
        bookingPrecedingDays: 'Number of Days',
        rule: 'Rule',
        incotermRuleIncoterm: {
          title: 'Incoterm Rule Specification',
          incoterm: 'Incoterm',
          ruleIncoterm: 'Incoterm Rule',
          active: 'Active'
        }
      },
      incotermRuleIncoterm: {
        title: 'Incoterm Rule Specification'
      },
      ibpIndicatorsDashboard: {
        title: 'Consolidated',
        indicatorName: 'Indicator Name',
        indicatorValue: 'Indicator Value',
        indicatorUnit: 'Indicator Unit'
      },
      ibpPurchasePlanDashboard: {
        title: 'Origination Plan',
        purchaseCompany: 'Company',
        purchaseContractReference: 'Purchase Contract/Split',
        purchaseCropYear: 'Crop Year',
        marketType: 'Market Type',
        positionMonth: 'Position Month',
        incoterm: 'Contract Terms',
        usBucket: 'US Bucket',
        totalQuantity: 'Originated Volume',
        totalQuantityUnit: 'Originated Volume Unit',
        plannedQuantity: 'Planned Volume',
        plannedQuantityUnit: 'Planned Volume Unit',
        remainingQuantity: 'Unplanned Volume',
        remainingQuantityUnit: 'Unplanned Volume Unit',
        isProjection: 'Is Projection',
        farmerName: 'Farmer',
        farmName: 'Farm',
        originSubRegion: 'Sub-Regional',
        originState: 'State',
        originCity: 'City'
      },
      ibpDemandFullfilmentDashboard: {
        title: 'IBP Demand Fullfilment Dashboard',
        saleCompany: 'Company',
        saleContractReference: 'Sale Contract/Split',
        saleCropYear: 'Crop Year',
        positionMonth: 'Position Month',
        shippingDate: 'Planned Shipping Month',
        marketType: 'Market Type',
        customer: 'Customer',
        saleType: 'Contract Type',
        usBucket: 'US Bucket',
        totalQuantity: 'Total Volume',
        totalQuantityUnit: 'Total Volume Unit',
        plannedQuantity: 'Planned Volume',
        plannedQuantityUnit: 'Planned Volume Unit',
        remainingQuantity: 'Remaining Volume',
        remainingQuantityUnit: 'Remaining Volume Unit',
        minimumQuantity: 'Minimum Volume',
        minimumQuantityUnit: 'Minimum Volume Unit',
        maximumQuantity: 'Maximum Volume',
        maximumQuantityUnit: 'Maximum Volume Unit',
        isProjection: 'Projection',
        destinationRegion: 'Destination Region',
        destinationCountry: 'Destination Country',
        destinationState: 'Destination State',
        destinationSubRegion: 'Destination Sub-Regional'
      },
      ibpDemandNotFullfiledByCottonConsolidated: {
        customer: 'Customer',
        remainingQuantity: 'Quantity (T)',
        destinationCountry: 'Country'
      },
      ibpSupplyChainCostProjectionDashboard: {
        title: 'Supply Chain Cost Projection - IBP Dashboard',
        operationType: 'Operation Type',
        planningMonth: 'Month',
        plannedCost: 'Planned Cost',
        plannedCostUnit: 'Planned Cost Unit',
        plannedQuantity: 'Moved Volume',
        plannedQuantityUnit: 'Moved Volume Unit'
      },
      supplyChainBudget: {
        title: 'Budget',
        beginHorizon: 'Begin',
        endHorizon: 'End',
        operationType: 'Operation Type',
        budgetValue: 'Budget Value',
        budgetValueUnity: 'Budget Value Unit'
      },
      weeklyOpenContractsProjectionDashboard: {
        title: 'Letter of Credit Projection',
        marketType: 'Market Type',
        saleContractReference: 'Sale Contract/Split',
        shippingDate: 'Shipping Date',
        creditDate: 'Opening Date',
        contractQuantity: 'Contract Quantity',
        contractQuantityUnit: 'Contract Quantity Unit',
        allocatedQuantity: 'Allocated Contract Quantity',
        allocatedQuantityUnit: 'Allocated Contract Quantity Unit',
        destinationRegion: 'Product Destination Region',
        destinationCountry: 'Product Destination Country',
        customer: 'Customer Name',
        contractProduct: 'Cotton Type',
        isCustomerVIP: 'Customer VIP'
      },
      weeklyBookingProjectionDashboard: {
        title: 'Booking Projection',
        carrierName: 'Carrier Name',
        bookingRef: 'Booking Ref #',
        bookingCode: 'Booking ID',
        bookingDate: 'Booking Date',
        burdenType: 'Burden Type',
        expirationDate: 'Expiration Date',
        weekNumber: 'Week Number',
        destinationRegion: 'Booking Destination Region',
        destinationCountry: 'Destination Country',
        originPort: 'Origin Port',
        destinationPort: 'Destination Port',
        totalContainerQuantity: 'Total Container Quantity',
        containerVolume: 'Container Volume',
        containerVolumeUnit: 'Container Volume Unit',
        usedContainerQuantity: 'Used Container Quantity',
        terminal: 'Terminal',
        saleContractReference: 'Sale Contract Reference'
      },
      weeklyPurchaseProjectionDashboard: {
        title: 'Origination Projection',
        purchaseContractReference: 'Purchase Contract/Split',
        positionMonth: 'Contract Position Month',
        shippingDate: 'Planned Loading Start',
        shippingEnd: 'Planned Loading End',
        availabilityDate: 'Cotton Availability Date',
        lotReference: 'Lot',
        planningWeek: 'Week Relative to Planned Loading Date',
        incoterm: 'Contract Term',
        contractProduct: 'Cotton Type',
        totalQuantity: 'Purchased Volume',
        totalQuantityUnity: 'Purchased Volume Unit',
        plannedQuantity: 'Planned Volume',
        plannedQuantityUnity: 'Planned Volume Unit',
        remainingQuantity: 'Unallocated Volume',
        remainingQuantityUnit: 'Unallocated Volume Unit',
        allocatedQuantity: 'Allocated Volume',
        allocatedQuantityUnity: 'Allocated Volume Unit',
        farmerName: 'Farmer Name',
        originCenter: 'Origin Center',
        originCenterType: 'Origin Center Type',
        farmName: 'Farm Name',
        originRegion: 'Region',
        originState: 'State',
        originSubRegion: 'Sub-Regional',
        originCity: 'City',
        isProjection: 'Projection'
      },
      weeklyDemandFullfilmentDashboard: {
        title: 'Demand Fullfilment',
        saleContractReference: 'Sale Contract/Split',
        positionMonth: 'Position Month',
        plannedServiceStartDate: 'Planned Service Start Period',
        plannedServiceEndDate: 'Planned Service End Period',
        marketType: 'Market Type',
        contractProduct: 'Cotton Type',
        carrierName: 'Carrier',
        terminal: 'Terminal',
        customer: 'Customer',
        minimumQuantity: 'Minimum Contract Quantity',
        minimumQuantityUnit: 'Minimum Contract Quantity Unit',
        maximumQuantity: 'Maximum Contract Quantity',
        maximumQuantityUnit: 'Maximum Contract Quantity Unit',
        totalQuantity: 'Total Contract Quantity',
        totalQuantityUnity: 'Total Contract Quantity Unit',
        plannedQuantity: 'Planned Contract Quantity',
        plannedQuantityUnity: 'Planned Contract Quantity Unit',
        remainingQuantity: 'Remaining Contract Quantity',
        remainingQuantityUnit: 'Remaining Contract Quantity Unit',
        destinationPort: 'Destination Port',
        destinationRegion: 'Destination Region',
        destinationState: 'Destination State',
        destinationSubRegion: 'Destination Sub-Regional',
        destinationCountry: 'Destination Country',
        isProjection: 'Projection',
        saleType: 'Contract Type'
      },
      weeklyCapacityUtilizationDashboard: {
        title: 'Capacity Utilization',
        operationType: 'Logistics Operation',
        purchaseContractReference: 'Purchase Contract/Split',
        shippingStartDate: 'Shipping Start',
        shippingEndDate: 'Shipping End',
        operatorName: 'Center',
        carrierName: 'Carrier',
        planningDate: 'Planning Date',
        planningWeek: 'Planning Week',
        plannedCapacity: 'Planned Volume',
        plannedCapacityUnity: 'Planned Volume Unit',
        operationCapacity: 'Contracted Volume',
        operationCapacityUnity: 'Contracted Volume Unit'
      },
      weeklyIndicatorsDashboard: {
        title: 'Indicators',
        indicatorName: 'Indicator Name',
        indicatorValue: 'Indicator Value',
        indicatorUnit: 'Indicator Unit'
      },

      reports: {
        title: 'Reports'
      },
      servicePlan: {
        title: 'Service Plan'
      },
      transferPlan: {
        title: 'Transfer Plan'
      },
      portPlan: {
        title: 'Port Plan'
      },
      carrierCapacityUtilization: {
        title: 'Carrier Capacity Utilization'
      },
      inventoryProjection: {
        title: 'Inventory Projection'
      },
      bookingUtilization: {
        title: 'Use of Booking'
      },
      leadtimebyCountry: {
        title: 'Additional Lead Time Destination Country',
        destinationCountry: 'Country',
        leadtime: 'Lead Time (days)',
        active: 'Active',
        beginPeriodDate: 'Begin',
        endPeriodDate: 'End',
        leadtimeCapacityContainer: {
          title: 'Ability to Not Use Containers',
          capacityContainer: 'Container Capacity (Ton))',
          minBales110: 'Min. Bales 110',
          maxBales110: 'Max. Bales 110',
          minBales124: 'Min. Bales 124',
          maxBales124: 'Max. Bales 124',
          leadtimebyCountry: 'Additional Lead Time Destination Country'
        }
      },
      leadtimeCapacityContainer: {
        title: 'Ability to Not Use Containers'
      },
      fullfilledContractsDashboard: {
        title: 'Fullfilled Contracts Dashboard',
        saleContractReference: 'Sale Contract Reference',
        positionMonth: 'Position Month',
        invoiceDate: 'Invoice Date',
        invoiceWeek: 'Invoice Week',
        marketType: 'Market Type',
        contractProduct: 'Contract Product',
        carrierName: 'Carrier Name',
        terminal: 'Terminal',
        customer: 'Customer',
        invoicedQuantity: 'Invoiced Quantity',
        invoicedQuantityUnit: 'Invoiced Quantity Unit',
        totalQuantity: 'Total Quantity',
        totalQuantityUnit: 'Total Quantity Unit',
        destinationPort: 'Destination Port',
        destinationCountry: 'Destination Country',
        lastUpdate: 'Last Update'
      },
      programmedContractsDashboard: {
        title: 'Programmed Contracts Dashboard'
      },
      programmedContractsShipmentsDashboard: {
        title: 'Programmed Contracts Shipments Dashboard'
      },
      port: {
        title: 'Ports',
        code: 'Code',
        observation: 'Observation',
        region: 'Region',
        country: 'Country',
        active: 'Active'
      },
      departureTerminal: {
        title: 'Departure Terminal',
        code: 'Code',
        port: 'Port',
        description: 'Description',
        active: 'Active',
        departureTerminalStuffingTerminal: {
          title: 'Stufffing Terminals',
          departureTerminal: 'Departure Terminal',
          stuffingTerminal: 'Stufffing Terminal',
          active: 'Active'
        },
        departureTerminalFreeTime: {
          title: 'Free Time',
          departureTerminal: 'Departure Terminal',
          beginHorizon: 'Begin Horizon',
          endHorizon: 'End Horizon',
          freeTimeDays: 'Free Time Days',
          active: 'Active'
        }
      },
      movementItas: {
        title: 'ITAS Movements',
        groupNumber: 'Group Id',
        planId: 'Plan Id',
        originCenter: 'Warehouse',
        expectedStartLoadDate: 'Origin Expected Start Load Date',
        expectedEndLoadDate: 'Origin Expected End Load Date',
        destinationCenter: 'Center Destination',
        destinationCustomer: 'Customer Destination',
        arrivalMinimumDate: 'Destination Arrival Minimum Date',
        arrivalMaximumDate: 'Destination Arrival Maximum Date',
        netWeight: 'Net Weight',
        netWeightUnity: 'Net Weight Unity',
        purchaseContractReference: 'Purchase Contract',
        lotReference: 'Lot',
        marks: 'Marks',
        saleContractReference: 'Allocated Sale Contract',
        lastItasUpdatedDate: 'Last Itas Updated Date',
        calculateAvailableCapacityButton: {
          label: 'Calculate Available Capacity'
        },
        events: {
          availableCapacitiesGeneratedSuccessfully:
            'Available capacities have been calculated successfully',
          errorOnGenerateAvailableCapacities:
            'Error when performing calculation of available capacities'
        }
      },
      storageTerminal: {
        title: 'Storage Terminal',
        code: 'Code',
        description: 'Description',
        terminalCenter: 'Stuffing Terminal',
        active: 'Active',
        isTakeOrPay: 'Take or Pay',
        storageTerminalInformation: {
          title: 'Storage Terminal Capacity',
          storageTerminal: 'Storage Terminal',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          storageCapacity: 'Storage Capacity',
          storageCapacityUnit: 'Storage Capacity Unity',
          active: 'Active'
        },
        storageTerminalCost: {
          title: 'Storage Terminal Cost',
          storageTerminal: 'Storage Terminal',
          beginHorizon: 'Begin',
          endHorizon: 'End',
          storageCost: 'Storage Cost',
          storageCostUnit: 'Storage Cost Unity',
          active: 'Active'
        },
        storageTerminalVolumeTakeOrPay: {
          title: 'Volume Take or Pay',
          storageTerminal: 'Storage Terminal',
          begin: 'Begin',
          end: 'End',
          volumeTakeOrPay: 'Volume Take Or Pay',
          volumeTakeOrPayUnity: 'Unit of measurement - Volume Take or Pay',
          active: 'Active'
        },
        storageTerminalTakeOrPayCost: {
          title: 'Take or Pay Costs',
          storageTerminal: 'Storage Terminal',
          begin: 'Begin',
          end: 'End',
          takeOrPayCost: 'Take or Pay Cost',
          takeOrPayCostUnity: 'Unit of measurement - Volume Take or Pay',
          active: 'Active'
        }
      },
      monthlyDemandAttendanceReport: {
        carrier: `Carrier`,
        carrierAvailableContainers: `Containers Capacity`,
        carrierCosts: `Carrier Costs (R$)`,
        carrierPlannedCapacityUtilizationUnit: `Unit - Stuffing Terminal Capacity`,
        carrierPlannedCapacityUtilizationValue: `Stuffing Terminal Capacity`,
        city: `City`,
        client: `Customer Code`,
        destinationRegion: `Destination Region`,
        purchaseContractCompany: `Company`,
        contractPlannedUsedVolume: `Planned Volume`,
        contractVolume: `Contract Volume`,
        country: `Country`,
        destinationArrivalExpectationDate: `Delivery Lead Time`,
        expectationOfReceipt: `Expectation of Receipt`,
        exportationReferencePeriod: `ETS`,
        purchaseContractIncoterm: `Purchase Incoterm`,
        marketType: `Market Type`,
        originPort: `Origin Port`,
        plannedContainersUsed: `Planned Utilization (Cntr)`,
        plannedPenaltyCost: `Planned Penalty Cost`,
        saleContractPositionMonth: `Sale Month Position`,
        premiumDiscountSale: `Premium Discount Sale`,
        producer: `Producer`,
        productionOrigin: `Warehouse`,
        purchaseContractCode: `Purchase`,
        purchaseContractType: `Purchase Contract Type`,
        purchaseSaleType: `Purchase Sale Type`,
        region: `Sub-Regional UK Gin`,
        saleContractCode: `Sale`,
        solverSaleSplitId: 'Sale Split',
        saleContractPenaltyCost: `Sale PNL Cost`,
        saleContractType: `Sale Contract Type`,
        saleCottonType: `US Bucket Sale`,
        saleDemandValue: `Sale Demand Value`,
        saleRisk: `Sale Risk`,
        shipmentDeadline: `Shipment Deadline`,
        state: `P Part 4`,
        stuffingTerminalUsed: `Stuffing Terminal Used`,
        title: `Monthly Demand Attendance`,
        vip: `VIP`,
        contractPlannedUsedVolumeUnit: 'Unit - Planned Volume',
        contractVolumeUnit: 'Unit - Contract Volume',
        plannedPenaltyCostUnit: 'Unit - Planned Penalty Cost',
        premiumDiscountSaleUnit: 'Unit - Premium Discount Sale',
        saleContractPenaltyCostUnit: 'Unit - Sale PNL Cost',
        saleDemandValueUnit: 'Unit - Sale Demand Value',
        purchaseCottonType: 'US Bucket Purchase',
        carrierDestinationCountry: 'Carrier Destination Country',
        carrierDestinationRegion: 'Carrier Region',
        splitSalePd: 'P/D Sale',
        splitSalePdUnit: 'Unit - P/D Sale',
        splitSalePlannedPd: 'P/D Gain',
        splitSalePlannedPdUnit: 'Unit - P/D Gain',
        purchaseContractPd: 'P/D Purchase',
        purchaseContractPdUnit: 'Unit - P/D Purchase',
        attendanceMonth: 'Attendance Month',
        attendanceType: 'Inventory',
        destination: 'Destination',
        destinationCountry: 'Destination Country',
        purchaseContractBeginShipPeriod: 'Receipt Deadline Date',
        purchaseContractCounterPartyName: 'Counter Party Code',
        purchaseContractCounterPartyRef: 'Counter Party Ref',
        purchaseContractCounterPartySector: 'FAS Indicator',
        purchaseContractCropYear: 'Crop Purchase',
        purchaseContractPositionMonth: 'Purchase Month Position',
        saleContractCropYear: 'Crop Sale',
        saleContractIncoterm: 'Sale Incoterm',
        saleDemandAttendedValue: 'Demand Attended',
        saleDemandAttendedValueUnit: 'Unit - Demand Attended',
        saleDemandAttendedValueUnitCode: 'Unit - Demand Attended',
        saleMaxPercentageQuantityTolerance: 'Max Tolerance',
        saleMinPercentageQuantityTolerance: 'Min Tolerance',
        stuffingCosts: 'Stuffing Costs (R$)',
        stuffingPlannedVolume: 'Stuffing Planned',
        stuffingPlannedVolumeUnit: 'Unit - Stuffing Planned',
        stuffingPlannedVolumeUnitCode: 'Unit - Stuffing Planned'
      },
      weeklyDemandAttendanceReport: {
        title: 'Weekly Demand Attendance',
        saleContractCropYear: 'Sale Crop Year',
        splitSaleContract: 'Sale',
        saleContractCompany: 'Sale Company',
        saleSplit: 'Sale Split',
        saleMarketType: 'Sale Market Type',
        client: 'Client',
        clientLocation: 'Client Location',
        vip: 'Vip',
        saleRisk: 'Sale Risk',
        cottonType: 'Cotton Type Sale',
        volume: 'Volume',
        volumeUnit: 'Unit - Volume',
        minimumTolerance: 'Minimum Tolerance',
        maximumTolerance: 'Maximum Tolerance',
        positionMonth: 'Position Month',
        shipmentDeadline: 'Shipment Deadline',
        salePd: 'P/D Sale',
        salePdUnit: 'Unit - P/D Sale',
        purchaseContractCropYear: 'Purchase Crop Year',
        splitPurchaseContract: 'Purchase',
        purchaseContractCompany: 'Purchase Company',
        lotRef: 'Lot Ref',
        counterParty: 'Counter Party',
        producer: 'Counterparty Ref',
        warehouse: 'Warehouse',
        lotBales: 'Lot Bales',
        lotVolume: 'Lot Volume',
        lotVolumeUnit: 'Unit - Lot Volume',
        plannedBales: 'Planned Bales',
        plannedVolume: 'Planned Volume',
        plannedVolumeUnit: 'Unit - Planned Volume',
        exportDeadline: 'Export Deadline',
        plannedOverLimit: 'Planned Over Limit',
        baleType: 'Bale Type',
        cottonTypeLot: 'Cotton Type Lot',
        lotPd: 'P/D Lot',
        lotPdUnit: 'Unit - P/D Lot',
        plannedPd: 'P/D Planned',
        plannedPdUnit: 'Unit - P/D Planned',
        cauleNivel: 'CauleNivel',
        picaoNivel: 'PicaoNivel',
        fumaginaIncid: 'FumaginaIncid',
        capimIncid: 'CapimIncid',
        sementeIncid: 'SementeIncid',
        infLength: 'InfLength',
        minimumLength: 'MinimumLength',
        maximumLength: 'MaximumLength',
        length: 'Length',
        infStrength: 'InfStrength',
        minimumStrength: 'MinimumStrength',
        maximumStrength: 'MaximumStrength',
        averageStrength: 'AverageStrength',
        infMic: 'InfMic',
        minimumMicronaire: 'MinimumMicronaire',
        maximumMicronaire: 'MaximumMicronaire',
        averageMicronaire: 'AverageMicronaire',
        supMic: 'SupMic',
        shortFibre: 'ShortFibre',
        part4: 'Part4',
        part5: 'Part5',
        marketSectorCode: 'MarketSectorCode',
        incoterm: 'Incoterm',
        loadDate: 'Load Date',
        loadWeek: 'Load Week',
        originCenter: 'Warehouse',
        originCenterType: 'Origin Center Type',
        originCity: 'Origin City',
        originState: 'Origin State',
        subRegional: 'Sub-Regional UK Gin',
        roadMap: 'Road Map',
        modal: 'Modal',
        modalType: 'Modal Type',
        unloadDate: 'Unload Date',
        unloadWeek: 'Unload Week',
        destinationCenter: 'Destination Center',
        destinationCenterType: 'Destination Center Type',
        pol: 'POL',
        destinationCity: 'Destination City',
        destinationState: 'Destination State',
        freightCost: 'Freight Cost',
        freightCostUnit: 'Unit - Freight Cost',
        plannedFreightCost: 'Planned Freight Cost',
        stuffingTerminal: 'Stuffing Terminal',
        stuffingLeadTimeDays: 'Stuffing LeadTime (days)',
        saleLeadTimeDays: 'Sale LeadTime (days)',
        freeTimeStuffingTerminalDays: 'FreeTime Stuffing Terminal (days)',
        overtimeStuffingDays: 'Overtime Stuffing (days)',
        ddlCargoBalanceDays: 'DDL Cargo Balance (days)',
        bookingRef: 'Booking Ref#',
        bookingCode: 'Booking Code',
        splitBooking: 'Split Booking',
        ship: 'Ship',
        trip: 'Trip',
        carrier: 'Carrier',
        departureTerminal: 'Departure Terminal',
        destinationRegion: 'Destination Region',
        destinationCountry: 'Destination Country',
        pod: 'POD',
        portTransshipment: 'Port Transshipment',
        deadlineDraft: 'Deadline Draft',
        deadlineCargo: 'Deadline Cargo',
        ets: 'ETS',
        etsWeek: 'ETS Week',
        etaPod: 'ETA POD',
        availableCntrs: 'Available Cntrs',
        availableBookingVolume: 'Available Booking Volume (MT)',
        plannedCntrs: 'Planned Cntrs',
        plannedVolumeMt: 'Planned Volume (MT)',
        carrierFreeTime: 'Carrier Free Time',
        cntrsWithdrawalDate: 'Cntrs Withdrawal Date',
        seaFreight: 'Sea Freight',
        seaFreightUnit: 'Unit - Sea Freight',
        plannedCostBkg: 'Planned Cost BKG',
        plannedCostBkgUnit: 'Unit - Planned Cost BKG',
        overtimeCost: 'Overtime Cost',
        overtimeCostUnit: 'Unit - Overtime Cost',
        plannedOvertimeCost: 'Planned Overtime Cost',
        plannedOvertimeCostUnit: 'Unit - Planned Overtime Cost',
        costPenaltySalesContract: 'Sale PNL Cost',
        costPenaltySalesContractUnit: 'Unit - Sale PNL Cost',
        plannedPenaltyCost: 'Planned Penalty Cost',
        plannedPenaltyCostUnit: 'Unit - Planned Penalty Cost',
        totalPlannedCost: 'Total Planned Cost',
        totalPlannedCostUnit: 'Unit - Total Planned Cost'
      },
      weeklyDemandAttendanceResumeReport: {
        title: 'Plano de Atendimento Semanal Resumo',
        purchaseSplitCode: 'Purchase Split',
        warehouse: 'Warehouse',
        lot: 'Lot',
        balesNumber: 'No. Of Bales',
        mark1: 'Mark',
        mark2: 'Mark',
        brainMark: 'Mark Brain'
      },
      weeklyTabularReportsCostProjection: {
        title: 'Cost Projection',
        logistics: 'BR Logistics',
        storage: 'Storage',
        stuffing: 'Stuffing',
        maritimeBooking: 'Maritime',
        weeklyTabularReportsCostProjectionLogistics: {
          departure: 'Departure',
          arrival: 'Arrival',
          weekDeparture: 'Departure Week',
          weekArrival: 'Arrival Week',
          subRegionOrigin: 'Sub-Regional UK Gin',
          stateOrigin: 'Origin State',
          cityOrigin: 'Origin City',
          route: 'Route',
          lat: 'Lat',
          long: 'Long',
          originCenter: 'Warehouse',
          originCenterType: 'Origin Center Type',
          company: 'Company',
          purchaseSplitContract: 'Purchase Contract/Split',
          cropYear: 'Crop Year',
          lotRef: 'Lot Ref',
          cottonType: 'Cotton Type',
          baleType: 'Bale Type',
          incoterm: 'Incoterm',
          modal: 'Modal',
          stateDestination: 'Destination State',
          cityDestination: 'Destination City',
          destinationCenter: 'Destination Center',
          destinationCenterType: 'Destination Center Type',
          originPort: 'Origin Port',
          client: 'Client',
          plannedBalesQuantity: 'Planned Bales Quantity',
          plannedVolume: 'Planned Volume',
          cost: 'Cost (R$)'
        },
        weeklyTabularReportsCostProjectionStorage: {
          referenceMonth: 'Reference Month',
          day: 'Day',
          week: 'Week',
          center: 'Center',
          centerType: 'Center Type',
          centerState: 'Center State',
          contractedVolume: 'Contracted Vol.',
          takeOrPayVolumeMonth: 'Take or Pay Vol. Month',
          autoUpdateVolume: 'Auto Update Vol.',
          plannedVolume: 'Planned Vol.',
          availableVolume: 'Available Vol.',
          takeOrPayCostMonth: 'Take or Pay Cost Month (R$)',
          additionalCostDay: 'Additional Cost Day (R$)',
          nonUtilizationPenaltyTakeOrPayDay:
            'Non Utilization Penalty Take or Pay Day (R$)',
          takeOrPay: 'Take or Pay'
        },
        weeklyTabularReportsCostProjectionStuffing: {
          referenceMonth: 'Reference Month',
          day: 'Day',
          week: 'Week',
          center: 'Center',
          centerType: 'Center Type',
          centerState: 'Center State',
          contractedVolume: 'Contracted Vol.',
          contractedVolumeUnit: 'Contracted Vol. Unit',
          takeOrPayVolumeMonth: 'Take or Pay Vol. Month',
          autoUpdateVolume: 'Auto Update Vol.',
          plannedVolume: 'Planned Vol.',
          availableVolume: 'Available Vol.',
          contractedCntr: 'Contracted Cntr',
          takeOrPayCntrMonth: 'Take or Pay Cntr Month',
          autoUpdateCntr: 'Auto Update Cntr',
          plannedCntr: 'Planned Cntr',
          availableCntr: 'Available Cntr',
          takeOrPayCostMonth: 'Take Or Pay Cost Month (R$)',
          additionalCostDay: 'Additional Cost Day (R$)',
          nonUtilizationPenaltyTakeOrPayMonth:
            'Non Utilization Penalty Take or Pay Month (R$)',
          takeOrPay: 'Take or Pay'
        },
        weeklyTabularReportsCostProjectionMaritimeBooking: {
          ets: 'ETS',
          week: 'Week',
          originPort: 'Origin Port',
          loadingTerminal: 'Departure Terminal',
          bookingRef: 'Booking Ref#',
          bookingCode: 'Booking Code',
          splitBooking: 'Split Booking',
          vessel: 'Ship',
          voyage: 'Trip',
          carrier: 'Carrier',
          destinationRegion: 'Destination Region',
          destinationCountry: 'Destination Country',
          destinationPort: 'Destination Port',
          company: 'Company',
          saleContractSplit: 'Sale Contract/Split',
          cropYear: 'Crop Year',
          saleSplit: 'Sale Split',
          plannedVolume: 'Planned Volume (Mt)',
          plannedContainerQty: 'Planned Container Quantity',
          cost: 'Cost (R$)'
        }
      },
      weeklyPlanStuffingReport: {
        title: 'Stuffing Plan',
        company: 'Company',
        instruction: 'Instruction',
        cropYear: 'Crop Year',
        splitSale: 'Split Sale',
        cntr: '# Cntr',
        fdsCntr: '# Fds/Cntr',
        total: 'Total',
        type: 'Type'
      },
      weeklySummaryPlanAllocationReport: {
        title: 'Summary Allocation Plan',
        splitPurchase: 'Purchase Split',
        warehouse: 'Warehouse',
        lotReference: 'Lot Ref',
        numBales: 'No. Of Bales',
        mark: 'Mark',
        mark1: 'Mark',
        markBrain: 'Mark Brain'
      }
    },
    enums: {
      notificationEvent: {
        general: 'General',
        scenario: 'Scenario'
      },
      scoNotificationEvent: {
        general: 'General',
        scenario: 'Scenario'
      },
      integrationType: {
        executedBooking: 'ITAS - Executed Booking',
        executedSale: 'ITAS - Executed Sale',
        inventory: 'ITAS - Inventory',
        planningBooking: 'ITAS - Planning Booking',
        planningOpenContracts: 'ITAS - Planning Open Contracts',
        planningPurchase: 'ITAS - Planning Purchase',
        planningSale: 'ITAS - Planning Sale',
        takeUpPurchaseContract: 'ITAS - Take-Up - Purchase Contract',
        inventoryTakeUp: 'ITAS - Take-Up - Inventory',
        bookingsStuffingPortal: 'ITAS - Bookings - Stuffing Portal',
        bookingsStuffingPortalCasa: 'ITAS - Bookings - Stuffing Portal - CASA',
        bookingsStuffingPortalFob: 'ITAS - Bookings - Stuffing Portal - FOB',
        brLogOnTime: 'ITAS - Br Log On Time',
        shipmentsStuffingPortal: 'ITAS - Shipments - Stuffing Portal',
        shipmentsStuffingPortalFob: 'ITAS - Shipments - Stuffing Portal - FOB',
        shipmentsStuffingPortalCasa:
          'ITAS - Shipments - Stuffing Portal - CASA',
        inventoryLogisticPortal: 'ITAS - Inventory - Logistic Portal',
        shipmentsLogisticPortal: 'ITAS - Shipments - Logistic Portal',
        shipmentsCockpit: 'ITAS - Shipments - Cockpit',
        inventoryCockpit: 'ITAS - Inventory - Cockpit',
        bookingsCockpit: 'ITAS - Bookings - Cockpit',
        deleteNotification: 'Delete Notification',
        shipmentsMovementItas: 'ITAS - Shipments - Movements Itas',
        inventoryStuffingPortalCasa:
          'ITAS - Inventory - Stuffing Portal - CASA',
        programmedBooking: 'ITAS - Programmed Booking',
        programmedShipments: 'ITAS - Programmed Shipments',
        inventoryIbpMonthlyReview: 'ITAS - Inventory - IBP Monthly Review',
        purchaseAndSalesIbpMonthlyReview:
          'ITAS - Purchase and Sales - IBP Monthly Review',
        bookingsIbpMonthlyReview: 'ITAS - Bookings - IBP Monthly Review',
        shipmentsIbpMonthlyReview: 'ITAS - Shipments - IBP Monthly Review'
      },
      integrationEvent: {
        created: 'Created',
        updated: 'Updated',
        deleted: 'Deleted',
        ignored: 'Ignored'
      },
      scenarioCargillType: {
        monthly: 'Monthly',
        weekly: 'Weekly',
        masterData: 'Master Data'
      },
      scenarioDataSourceType: {
        blank: 'Blank',
        copy: 'Copy',
        master: 'Master'
      },
      weekDaysType: {
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday'
      },
      goalType: {
        salesForecastAccuracy: 'Sales Forecast Accuracy',
        originationForecastAccuracy: 'Origination Forecast Accuracy',
        onTimeInFull: 'On Time In Full'
      },
      capacityUtilizationType: {
        railway: 'Railroad',
        waterway: 'Waterway',
        stuffing: 'Stuffing',
        shipowner: 'Shipowner',
        warehouse: 'Warehouse'
      },
      target: {
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly'
      },
      unityCategory: {
        mass: 'Mass',
        time: 'Time',
        currency: 'Currency',
        length: 'Length',
        percentage: 'Percentage',
        volume: 'Volume',
        area: 'Area',
        unit: 'Unit',
        energyConsumption: 'Energy Consumption',
        massPerTime: 'Mass / Time',
        currencyPerTime: 'Currency / Time',
        currencyPerMass: 'Currency / Mass',
        currencyPerUnit: 'Currency / Unit',
        timePerTime: 'Time / Time',
        massPerCurrency: 'Mass / Currency',
        currencyPointsPerMass: 'Currency Points / Mass',
        centsPerMass: 'Cents / Mass'
      },
      ruleType: {
        previousDaysBookingAppointment:
          'Number of days prior to booking appointment',
        previousDaysReceiptSalesContract:
          'Number of days preceding the expected receipt of the sales contract'
      },
      incotermRuleType: {
        cargillBRLogisticsCapacityCostControl:
          'Cost/Control Logistics BR are the responsibility of Cargill',
        cargillBRReceivingCapacityCostControl:
          'Cost/Receiving Capacity Control/Stuffing are the responsibility of Cargill',
        producerTotalCostControl:
          'Costs/Control of shipping to the Customer are the responsibility of the Producer',
        cargillMaritimeCostControl:
          "Only Maritime Cost and Control (freight and export capabilities) are Cargill's responsibility",
        cargillMinimumWithdrawalControl:
          "Minimum Withdrawal Control is Cargill's Responsibility"
      },
      operationType: {
        transfer: 'Transfer',
        sale: 'Sale'
      },
      marketType: {
        bt: 'Both',
        em: 'External Market',
        im: 'Internal Market'
      },
      checkType: {
        ok: 'Ok',
        bottom: 'Bottom',
        higher: 'Higher'
      },
      saleType: {
        projection: 'Projection',
        real: 'Real',
        both: 'Both'
      },
      contractType: {
        projection: 'Projection',
        real: 'Real'
      },
      attendanceType: {
        lot: 'Lot',
        purchaseContract: 'Purchase Contract'
      },
      company: {
        br: 'BR',
        rg: 'RG'
      },
      spreadReference: {
        minAvg: 'Min. and Avg',
        minMax: 'Min. and Max.'
      },
      consolidatedType: {
        ibpVendasAtendidas: 'Sales Attended',
        ibpAtendimentoDemandaMi: 'Demand Fulfillment',
        ibpAtendimentoDemandaMe: 'External Market Demand Fulfillment',
        ibpTotalOriginado: 'Total Originated',
        ibpVolumeOriginado: 'Use of Originated Volume',
        ibpAbertoParaVenda: 'Cotton Available',
        ibpEstufagem: 'Stuffing',
        ibpArmador: 'Shipowner',
        ibpArmazem: 'Storage',
        ibpProjecaoCustoCadeia: 'Chain Cost Projection',
        ibpBudget: 'Budget',
        ibpCapacityUtilization: 'Capacity Utilization'
      },
      operationTypeOutput: {
        roadFreightCostType: 'Road Freight',
        railwayFreightCostType: 'Railway Freight',
        waterwayFreightCostType: 'Waterway Freight',
        stuffingPortTerminalCostType: 'Port Stuffing Terminal',
        stuffingRailwayTerminalCostType: 'Railway Stuffing Terminal',
        stuffingWaterwayTerminalCostType: 'Waterway Stuffing Terminal',
        storageCostType: 'Interior Warehouse',
        shipownerCapacityCostType: 'Shipowner'
      },
      yesOrNo: {
        yes: 'Yes',
        no: 'No',
        both: 'Both'
      },
      bookingTypes: {
        withoutAlocation: 'Without Alocation',
        partialAlocation: 'Partial Alocation',
        both: 'Both'
      },
      weeklyConsolidatedType: {
        demandFullfilmentExternalMarket: 'Demand Fullfilment External Market',
        demandFullfilmentInternalMarket: 'Demand Fullfilment Internal Market',
        originationProjectionCif: 'Origination Projection (CIF)',
        originationProjectionFob: 'Origination Projection (FOB)',
        originationProjectionExWorks: 'Origination Projection (ExWorks)',
        originationProjectionOthers: 'OriginationProjection (Others)',
        bookingsUsedProjection: 'Bookings Used Projection',
        bookingsUsedProjectionNoBinding: 'Bookings Used Projection No Binding',
        openContractsProjection: 'Open Contracts Projection',
        openContractsWithoutAllocation: 'Open Contracts Without Allocation',
        allocatedCottonProjection: 'Allocated Cotton Projection',
        cottonWithoutAllocation: 'Cotton Without Allocation',
        stuffingCapacityProjection: 'Stuffing Capacity Projection',
        stuffingCapacityNotUsed: 'Stuffing Capacity Not Used'
      },
      penaltyType: {
        shippingPeriod: 'Shipping Period',
        arrivalCustomerPeriod: 'Arrival Customer Period'
      },
      optimizationOperation: {
        optimization: 'Optimization',
        validation: 'Validation'
      },
      optimizationStatus: {
        optimal: 'Optimal',
        error: 'Error',
        creatingInput: 'Creating input',
        queued: 'Queued',
        pending: 'Pending',
        readingSolution: 'Reading solution',
        userInterrupted: 'User interrupted',
        requestingOptimizationStop: 'Requesting optimization stop',
        finished: 'Finished',
        writingResults: 'Writing results',
        errorWritingInput: 'Error writing input',
        errorAutomaticCapacity: 'Error calculating available capacities',
        inputCreationQueued: 'Input creation queued',
        waitingForLogs: 'Waiting for logs',
        logReading: 'Log reading',
        writingLogs: 'Writing logs'
      },
      alertType: {
        warning: 'Warning',
        error: 'Error',
        information: 'Information'
      },
      bookingSplitType: {
        allocation: 'Allocation',
        notUsed: 'Not Used'
      },
      portStuffingTerminalFreeTimeCondition: {
        fromContainerRetreat: 'From Container Retreat',
        fromContainerStuffing: 'From Container Stuffing'
      },
      baleType: {
        bigBale: '124',
        smallBale: '110'
      }
    },
    parameters: {
      options: {
        WEEK_BEGINNING: 'Beginning of the week',
        WEEK_ENDING: 'Ending of the Week',
        MIDDLE_WEEK: 'Middle of the Week',
        MIN_COSTS: 'Cost Minimization',
        SUPER_HIGH: 'Super High',
        HIGH: 'High',
        MEDIUM: 'Medium',
        LOW: 'Low'
      },
      items: {
        CropYear: 'Crop Year',
        PurchaseContractProjConsumption:
          'Relevance on the use of a signed purchase agreement',
        PurchaseContractUtilization:
          'Relevance on contract prices/purchase projections',
        ICMSLimitDaysWeight: 'Relevance on ICMS deadlines on export',
        PDCost: 'Relevance on Premium/Discount',
        ReleaseDateMaterialWeight:
          'Relevance on the use of longer available cotton',
        PenaltyByDeadline: 'Relevance on penalties of sales contracts',
        PenaltyByPositionMonth:
          'Relevance on Position Month of Sales Contracts',
        StuffedMaterialEarly: 'Relevance on the use of Carrier Free Time',
        VIPClientsNotAttendance: 'VIP Customer Service',
        RiskSaleContract: 'Sales Risk service',
        MinimumContainerOccupancy: 'Minimum Container Occupancy',
        BarrierAndCrossOver: 'Barrier and Cross Over',
        Gap: 'Level of Accuracy',
        ConsiderMinimizingUseBatchesFulfillingSale:
          'Consider minimizing the use of batches when handling a sale',
        ConsiderMinimizingPurchaseUsageServicingSale:
          'Consider minimizing purchase usage in servicing a sale',
        RealSaleContractNotAttendance:
          'Relevance on compliance with the signed sales contract',
        BIReceiptDate: 'Date of receipt BI of the sales contract',
        ReleaseMaterialDeadlineCalculationType:
          'Reference for arrival at an Internal Market customer',
        DefaultValuesParameters: 'Container Capacity (ton)',
        FulfillEMBeforePositionMonthStartDate:
          'Allow to fulfill sales before Position Month',
        FulfillIMBeforePositionMonthStartDate:
          'Allow to fulfill sales before Position Month',
        ConsiderCropRestrictionOnService:
          'Consider Crop Restriction on Service',
        FlexibilityToExceedMaxApplication:
          'Flexibility for thickness % Max Application (Quality and Type)',
        DisconsiderTheTypeOfBale:
          'Disconsider the type of bale (allowing mixing)',
        MaximumTimeToUsePurchaseContractOrLot:
          'Maximum time (months) to try to use a purchase contract or lot from the farm',
        EMAllowedMonthsInAdvanceBeforePositionMonthStartDate:
          'Allowed months in advance for attendance before Position Month',
        IMAllowedMonthsInAdvanceBeforePositionMonthStartDate:
          'Allowed months in advance for attendance before Position Month',
        EMMaximumNumberAttemptFulfillSale:
          'Maximum number (months) to attempt to fulfill a sale (after Position Month)',
        IMMaximumNumberAttemptFulfillSale:
          'Maximum number (months) to attempt to fulfill a sale (after Position Month)',
        ObjectiveFunction: 'Objective Function',
        PrioritizeBatchesICMS:
          'Prioritize batches whose ICMS is close to the Expiration date',
        RestrictionDaysCarrierFreeTime:
          'Restriction of Days after Carrier Free Time',
        RestrictionDeadlineCargoBooking:
          'Days Before Deadline Cargo Booking Restriction',

        ConsiderStorage: 'Consider Storage',
        StorageTimeMIMonth: 'MI Storage Time (Months)',
        StorageTimeMEMonth: 'ME Storage Time (Months)',
        StorageTimeMEMTMonth: 'Storage Time ME - MT (Months)',
        EMMinimumLimitContainersSplitSalePartialAllowed:
          'Minimum limit of Containers for Split Sales ME Partial Allowed',
        EMMaximumLimitContainersSplitSalePartialAllowed:
          'Maximum limit of Containers for Split Sale ME Partial Allowed',
        PrioritizeInitialStockUsage:
          'Prioritize Initial Stock Usage for Fulfillment',
        PrioritizeOlderBatches: 'Enable older batch prioritization',
        NumDaysToConsiderBatchAsOld: 'Number of days in stock'
      },
      groups: {
        ObjectiveFunction: 'Objective Function',
        GeneralParameters: 'General Parameters',
        PenaltyWeight: 'Service Priority',
        ExternalMarket: 'External Market',
        InternalMarket: 'Internal Market',
        Storage: 'Storage',
        OlderBatches: 'Prioritize Older Batches'
      },
      misc: {
        placeholder: 'Type '
      },
      errors: {
        notInteger: 'Value of {field} is not a integer',
        penaltyWeight: {
          outOfRange: 'Field weight {field} is out of range 1 to 1000',
          outOfRangeFlexibility:
            'Field weight {field} is out of range greater than or equal to 5',
          duplicateWeights: 'The weights of fields {fields} are equal'
        }
      }
    }
  }
}
